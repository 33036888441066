<template>
  <div class="checkout">
    <Top
      :prev="{ text: 'К выбору дизайна', name: 'create-order' }"
      :state="{ value: 3, text: 'Оформление заказа' }"
    />
    <Contacts class="forms" @sent="isSent = true" />
    <!--    <PopupThanks v-if="isSent" @close="isSent = false" />-->
  </div>
</template>

<script>
import Top from "../__top__";

import Contacts from "./__contacts__";

export default {
  components: {
    Top,
    Contacts,
  },
  data() {
    return {
      isSent: false,
    };
  },
};
</script>
